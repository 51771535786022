import BaseService from "./base-service";
import type { ResetPasswordViewModel } from "@/types/models/reset-password-view-model";
import type { RegisterViewModel } from "@/types/models/register-view-model";
import type { ConfirmEmailPostViewModel } from "@/types/models/confirm-email-post-view-model";
import type { ChangePasswordModel } from "@/types/models/change-password-model";
import type { ChangeBaseInfoViewModel } from "@/types/models/change-base-info-view-model";
import type { UserBaseInfoViewModel } from "@/types/models/store/user-base-info-view-model";

class AccountService extends BaseService {
    async forgotPassword(model: { email: string }) {
        return this.post('/auth/forgotPassword', model);
    }

    async resetPassword(data: ResetPasswordViewModel) {
        return await this.post('/auth/resetPassword', data);
    }

    async register(data: RegisterViewModel) {
        return await this.post('/auth/register', data);
    }

    async getId() {
        return await this.get('/profile/getId');
    }

    async getUserInfo() {
        return await this.get<UserBaseInfoViewModel>('/profile/GetUserInfo');
    }

    async changeBaseInfo(data: ChangeBaseInfoViewModel) {
        return await this.post('/profile/ChangeBaseInfo', data);
    }

    async changeUserPassword(data: ChangePasswordModel) {
        return await this.post('/profile/ChangePassword', data);
    }

    async confirmEmail(data: ConfirmEmailPostViewModel) {
        return await this.post('/Auth/ConfirmEmail', data);
    }
}

export default new AccountService();